import { RouteObject } from 'react-router-dom';

import ProjectsPage from '.';
import SingleProjectPage from './SingleProjectPage';
import MrqPage from './ProjectMrqPage';
import ProjectRaciPage from './ProjectRaciPage';
import ProjectTaskPage from './ProjectTaskPage';
import DCSTab from './SingleProjectPage/tabs/DCSTab';

export const paths = {
  myProjects: '/projects',
  singleProject: '/projects/:projectId',
  projectTabs: '/projects/:projectId/:projectTab',
  mcsTabs: '/projects/:projectId/:projectTab/:mcsTab',
  mcsTab: 'mcs',
  pcsTab: 'pcs',
  mrq: '/projects/:projectId/mrq',
  raci: '/projects/:equipId/raci',
  task: '/projects/:projectId/task',
};

export const projectsRoutes: RouteObject[] = [
  {
    path: paths.myProjects,
    element: <ProjectsPage />,
  },
  {
    path: paths.singleProject,
    element: <SingleProjectPage />,
  },
  {
    path: paths.projectTabs,
    element: <SingleProjectPage />,
  },
  {
    path: paths.mcsTabs,
    element: <SingleProjectPage />,
  },
  {
    path: paths.mrq,
    element: <MrqPage />,
  },
  {
    path: paths.raci,
    element: <DCSTab />,
  },
  {
    path: paths.task,
    element: <ProjectTaskPage />,
  },
];
