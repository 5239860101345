import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ProjectTable from './components/ProjectTable';
import User from '../UsersPage/interface/userInterface';
import { getUsers } from '../UsersPage/api';
import Viewer from './Viewer';
import { MRT_Row } from 'material-react-table';
import Project from './interfaces/projectInterface';
import CommentsDialog from '../../common/components/dialogs/CommentsDialog';
import { AuthContext } from '../../common/contexts/authContext';
import { loginVerify } from '../LoginPage/api';
import { AuthContextMSAL } from '../../common/contexts/mslAuthContext';

const ProjectsPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [viewerModalState, setViewerModalState] = useState(false);
  const [commentsModalState, setCommentsDialogState] = useState(false);
  const [clickedProject, setClickedProject] = useState<string>('');

  const handleOpen = (row: MRT_Row<Project>) => {
    console.log('row', row.original.name);
    // setClickedProject(row.original.name);
    setViewerModalState(true);
  };
  const handleCommentDialogOpen = (row: MRT_Row<Project>) => {
    console.log('row', row.original);
    setClickedProject(row.original.id);
    setCommentsDialogState(true);
  };
  return (
    <Box id="projects-page" sx={{ padding: '2rem' }}>
      <Stack
        sx={{ paddingBottom: '2rem' }}
        direction={'row'}
        justifyContent="space-between"
        height={'3rem'}
        alignItems="center"
      >
        <h2>Projects</h2>
        {/* <Button
          variant="contained"
          sx={{ borderRadius: '0rem', width: '10rem', height: '2rem' }}
        >
          + Add Project
        </Button> */}
      </Stack>
      <ProjectTable
        users={users}
        openViewerModal={handleOpen}
        openCommentsModal={handleCommentDialogOpen}
      />
      <CommentsDialog
        open={commentsModalState}
        parentId={clickedProject}
        handleClose={() => {
          setClickedProject('');
          setCommentsDialogState(false);
        }}
        parentType={'project'}
      />
      <Viewer
        open={viewerModalState}
        handleClose={(event) => {
          setViewerModalState(false);
        }}
        projectName={clickedProject}
      />
    </Box>
  );
};

export default ProjectsPage;
