import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { AuthContextMSAL } from './mslAuthContext';
import { loginVerify } from '../../pages/LoginPage/api';
type user = {
  name: string;
  role: string;
};

interface authContextInterface {
  setRole: (role: string) => void;
  role?: string;
  user?: user;
  login: (user: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<authContextInterface>({
  setRole: () => {},
  role: undefined,
  user: undefined,
  login: () => {},
  logout: () => {},
});

const AuthContextProvider = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const { accessToken } = useContext(AuthContextMSAL);
  const [user, setUser] = useState<user | undefined>(undefined);
  const [role, setRole] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (accessToken) {
      console.log('verifyingggggggg');

      const getAuth = async () => {
        const resp = await loginVerify(accessToken);
        setRole(resp.data.role);
      };
      getAuth();
    }
  }, [accessToken]);

  const login = (userName: string): void => {
    setUser({ name: userName, role: 'admin' });
  };

  const logout = () => {
    setUser(undefined);
  };
  const contectValues: authContextInterface = {
    role,
    setRole,
    user,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contectValues}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
