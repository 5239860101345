import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable,
  MRT_RowData,
  MaterialReactTable,
  createRow,
  MRT_EditActionButtons,
} from 'material-react-table';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';

import { minimalMuiTableProps } from '../../../common/style/muiProps';
import { useParams } from 'react-router-dom';
import CreateProjectDialog from './CreateProjectModal';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
interface EditableTableProps<T extends MRT_RowData> {
  name: string;
  columns: MRT_ColumnDef<T>[];
  displayMode?: 'row' | 'modal' | 'custom';
  actions?: {
    validateItem: (values: T) => Record<string, string>;
  };
  api: {
    createItem: (parentId: string, values: T) => Promise<void>;
    getItems: (parentId: string) => Promise<T[]>;
    updateItem: (values: T) => Promise<void>;
    deleteItem: (id: string) => Promise<void>;
  };
  enableEditing: boolean;
  enableCreate: boolean;
  initialData: T;
  additionalProps?: { [key: string]: any };
  parentId: string;
}

export const EditableTable = <T extends MRT_RowData>({
  name,
  columns,
  displayMode,
  actions,
  api,
  enableEditing,
  enableCreate,
  initialData,
  additionalProps,
  parentId,
}: EditableTableProps<T>) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [data, setData] = useState<T[]>([]);
  const [refresh, setRefresh] = useState<true | false>(false);
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const user = useMsal().accounts[0].username;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const items = await api.getItems(parentId);
        // console.log('name', name, items);
        setData(items);
        setIsLoading(false);
      } catch (error) {
        // Handle error
        setIsError(true);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [api, refresh]);
  const navigate = useNavigate();

  //   const handleCreateItem: MRT_TableOptions<T>['onCreatingRowSave'] = async ({
  //     values,
  //     // table,
  //   }) => {
  //     console.log('vvvvvvvv', values);

  //     try {
  //       // const newValidationErrors = actions?.validateItem(values as T);
  //       // if (Object.values(newValidationErrors).some((error) => error)) {
  //       //     setValidationErrors(newValidationErrors);
  //       //     return;
  //       // }
  //       let item = { ...values };
  //       // TODO: Remove this and add it as parentId in create API call
  //       if (projectId) {
  //         item = { ...values, projectId: projectId };
  //         console.log({ projectId });
  //       }
  //       console.log({ values });
  //       setValidationErrors({});
  //       await api.createItem(parentId, item as T);
  //       //   table.setCreatingRow(null);
  //       setRefresh((prev) => !prev);
  //     } catch (error) {
  //       // Handle error
  //     }
  //   };
  const handleCreateItem = async (values: any) => {
    console.log(values, process.env.CLIENT_NAME);
    await api.createItem(parentId, values);
    setRefresh((prev) => !prev);
  };

  const handleSaveItem: MRT_TableOptions<T>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    try {
      // const newValidationErrors = actions.validateItem(values as T);
      // if (Object.values(newValidationErrors).some((error) => error)) {
      //     setValidationErrors(newValidationErrors);
      //     return;
      // }
      setValidationErrors({});
      console.log({ values });
      console.log('updating');
      await api.updateItem(values as T);
      table.setEditingRow(null);
      setRefresh((prev) => !prev);
    } catch (error) {
      // Handle error
    }
  };

  const openArchiveConfirmModal = (row: MRT_Row<T>) => {
    if (window.confirm('Are you sure you want to archive this item?')) {
      // api.deleteItem(row.original.id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    createDisplayMode: displayMode,
    editDisplayMode: displayMode,
    enableEditing: true,

    // enableCreate: true,
    getRowId: (row) => row.id,
    // onCreatingRowSave: handleCreateItem,
    onEditingRowSave: handleSaveItem,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Archive">
          <IconButton
            color="primary"
            onClick={() => openArchiveConfirmModal(row)}
          >
            <ArchiveIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        sx={{ display: enableCreate ? 'flex' : 'none' }}
        // disabled={!enableCreate}
        startIcon={<AddIcon />}
        onClick={() => {
          // table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          //   table.setCreatingRow(createRow(table, initialData));
          setIsDialogOpen(true);
        }}
      >
        Add {name}
      </Button>
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Create New {name}</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Edit {name}</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    ...minimalMuiTableProps,
    ...additionalProps,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    positionGlobalFilter: 'left',
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      showGlobalFilter: false,
      columnVisibility: { id: false },
    },
    //customize the MRT components
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15],
      variant: 'outlined',
    },
    positionActionsColumn: 'last',

    state: {
      isLoading: isLoading,
      isSaving: false,
      showAlertBanner: isError,
      showProgressBars: false,
    },
    muiTableBodyRowProps: ({ isDetailPanel, row, table }) => ({
      onClick: (event) => {
        const element = event.target as HTMLElement;
        console.log('hhhhere', row, element.classList);

        if (element.classList[0] === 'MuiTableCell-root') {
          navigate(`/mcs/${row.id}#mcs-tab=Drawing%20BOM`);
        }
      },
      sx: {
        cursor: 'pointer',
      },
    }),
  });

  return (
    <>
      <MaterialReactTable table={table} />;
      <CreateProjectDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        onSave={handleCreateItem}
      />
    </>
  );
};
