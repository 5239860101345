import { autodesk_axios as axios } from '../../common/api/axios';

export const loginVerify = async (token) => {
  console.log('token in authh', token);
  const response = await axios.get('/dev/verifyToken', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log('resssssssssssssssssssss', response);

  return response;
};
