import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  orderBy,
  serverTimestamp,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import AWS from 'aws-sdk';
import { db } from './firebaseConfig';

// AWS S3 Configuration
const s3 = new AWS.S3({
  accessKeyId: 'AKIA37N33SVDRCF2KPYI',
  secretAccessKey: 'OeLdNN33LnmdRpaJq/sx8Q5trMnAFGOfjnqUh0mm',
  region: 'eu-north-1',
});

const bucketName = 'enerfacas'; // Replace with your S3 bucket name

/**
 * Upload an image to AWS S3 and return the URL.
 * @param {File} file - The file to upload.
 * @returns {Promise<string>} - The URL of the uploaded image.
 */
export const uploadImageToS3 = async (file) => {
  try {
    const fileName = `${Date.now()}-${file.name}`;
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ContentType: file.type, // Keep content type for correct MIME
    };

    // Upload the file to S3
    const data = await s3.upload(params).promise();

    // Return the file URL
    return data.Location;
  } catch (error) {
    console.error('Error uploading image to S3:', error);
    throw error;
  }
};

/**
 * Add a comment with optional image attachment.
 * @param {string} parentId - The ID of the parent item.
 * @param {string} parentType - The type of the parent item.
 * @param {string} commentText - The comment text.
 * @param {string} userName - The name of the user adding the comment.
 * @param {File|null} attachmentFile - The optional image file.
 * @returns {Promise<void>}
 */
export const addComment = async (
  parentId,
  parentType,
  commentText,
  userName,
  attachmentFile = null,
) => {
  try {
    let attachmentUrl = null;

    if (attachmentFile) {
      attachmentUrl = await uploadImageToS3(attachmentFile); // Upload to S3 and get the URL
    }

    const commentData = {
      parentId,
      parentType,
      commentText,
      userName,
      attachment: attachmentUrl,
      timestamp: serverTimestamp(),
    };

    await addDoc(collection(db, 'comments'), commentData);
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

/**
 * Fetch all comments for a given parentId and parentType.
 * @param {string} parentId - The ID of the parent item.
 * @param {string} parentType - The type of the parent item.
 * @returns {Promise<Array>} - An array of comments.
 */
export const fetchComments = async (parentId, parentType) => {
  try {
    const q = query(
      collection(db, 'comments'),
      where('parentId', '==', parentId),
      where('parentType', '==', parentType),
      orderBy('timestamp'),
    );
    const querySnapshot = await getDocs(q);

    const comments = [];
    querySnapshot.forEach((doc) => {
      comments.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return comments; // Returns the comments array
  } catch (error) {
    console.error('Error fetching comments:', error);
    throw error;
  }
};

export const updateComment = async (
  commentId,
  newCommentText,
  newAttachment = null,
) => {
  try {
    const commentRef = doc(db, 'comments', commentId);
    const updatedData = {
      commentText: newCommentText,
      ...(newAttachment && { attachment: newAttachment }),
    };
    await updateDoc(commentRef, updatedData);
  } catch (error) {
    console.error('Error updating comment:', error);
    throw error;
  }
};

/**
 * Delete a comment by its ID.
 */
export const deleteComment = async (commentId) => {
  try {
    const commentRef = doc(db, 'comments', commentId);
    await deleteDoc(commentRef);
  } catch (error) {
    console.error('Error deleting comment:', error);
    throw error;
  }
};
