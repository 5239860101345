import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAtYJuZvCiJ-Ml5QBGVv31r_aI3SUV2mcM',
  authDomain: 'enerfaciot.firebaseapp.com',
  projectId: 'enerfaciot',
  storageBucket: 'enerfaciot.appspot.com',
  messagingSenderId: '810793975251',
  appId: '1:810793975251:web:4a26f5036084aaeb248326',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Storage
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
